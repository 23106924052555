import React, { useState } from "react";
import Styled from "styled-components";
import { images, textInputs } from "../../../../../constants/constants";
import { Wrapper } from "../../../../../components/Control/Flexbox";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { CenteredLogoImg } from "../../../../../golbalStyles/global.styled";
import formatDate from "../../../../../components/Control/formatDate";
import {
  MenuItem,
  Select,
  makeStyles,
  IconButton,
  Tooltip,
  Box,
} from "@material-ui/core";
import { CloudUpload, Delete, DeleteOutline } from "@material-ui/icons";
import styled from "styled-components";
import { UploadImageIconButton } from "../../../../../components/UploadIconButton";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const ActionCell = styled.td`
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  min-height: 80px;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  min-height: 60px;
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 8px !important;
  background-color: ${(props) =>
    props.variant === "upload" ? "#f5f5f5" : "transparent"};

  &:hover {
    background-color: ${(props) =>
      props.variant === "upload" ? "#eeeeee" : "rgba(0, 0, 0, 0.04)"};
  }
`;

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;
  th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
        b{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        strong{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        word-wrap: break-word;
    }
    tbody th {
    text-align: center;
    }

    .input {
      outline: none;
      width: 100px;
      border: none;
      height: 50px;
      text-align:center;
  }
    
`;

const PredictiveTableForm = ({
  checkListMachineData,
  changeLanguage,
  handleChecklist,
  checklistType,
  isView,
  uploadFile,
  deleteRow,
}) => {
  const [render, setRender] = useState(false);
  const classes = dropdownStyles();

  const menuItemStyles = {
    display: "flex",
    width: "60px",
    alignItems: "center",
    justifyContent: "center",
  };

  const CustomSelect = (name, value, index, pos, neg, Width) => {
    return (
      <Select
        classes={{
          root: classes.root,
          select: classes.select,
        }}
        inputProps={{ IconComponent: () => null }}
        style={{
          fontSize: "0.8rem",
          width: Width,
          display: "flex",

          alignItems: "center",
          justifyContent: "center",
        }}
        disableUnderline
        name={name}
        value={value}
        onChange={(e) => {
          handleChecklist(e, index);
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>

        <MenuItem value={pos}>
          <span
            style={{
              fontSize: "1.3rem",
              color: "#40c057",
              ...menuItemStyles,
            }}
          >
            {pos}
          </span>
        </MenuItem>
        <MenuItem value={neg}>
          <span
            style={{
              fontSize: "1.3rem",
              color: "#f03e3e",
              ...menuItemStyles,
            }}
          >
            {neg}
          </span>
        </MenuItem>
      </Select>
    );
  };

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <Table id={checkListMachineData.document_type} data-default-wrap="true">
        <thead>
          <tr>
            <th
              data-img={images.arcLogo}
              colSpan="6"
              rowSpan={2}
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
            >
              <CenteredLogoImg>
                <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>PREDICTIVE MAINTENANCE CHECKLIST</span>
              </CenteredLogoImg>
            </th>
            <th align="left" colSpan={2}>
              Doc.No.:-FM/XLI/MT/015
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={2}>
              FOR THE MONTH :
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={4}>
              Machine Name :- {checkListMachineData.machine_name}
            </th>
            <th align="left" rowSpan={2} colSpan={4}>
              SCHEDULE : MONTHLY (M) / QUATERLY (Q) / HALF YEARLY (H) / ANNUALY
              (A)
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={4}>
              Machine Name :- {checkListMachineData.machine_no}
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={4}>
              Dept./Section:-
              {isView ? (
                checkListMachineData.department
              ) : (
                <input
                  autoComplete="off"
                  className="input"
                  style={{ border: "2px solid #f9e0e0", width: "200px" }}
                  name="department"
                  defaultValue={checkListMachineData.department}
                  onChange={(e) => {
                    checkListMachineData.department = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            <th align="left" colSpan={3}>
              Schedule :
              {isView ? (
                checkListMachineData.schedule
              ) : (
                <input
                  style={{ border: "2px solid #f9e0e0", width: "200px" }}
                  autoComplete="off"
                  className="input"
                  name="schedule"
                  defaultValue={checkListMachineData.schedule}
                  onChange={(e) => {
                    checkListMachineData.schedule = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            <th align="left" colSpan={1}>
              Date:-
              {isView ? (
                formatDate(checkListMachineData.last_maintenance_date)
              ) : (
                <Wrapper style={{ gap: "5px" }}>
                  <DatePicker
                    name="last_maintenance_date"
                    format="dd/MM/yyyy"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      backgroundColor: "#f8f9fa",
                    }}
                    value={checkListMachineData.last_maintenance_date || null}
                    onChange={(date) => {
                      checkListMachineData.last_maintenance_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                </Wrapper>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowSpan={2} style={{ width: "100px" }}>
              S NO.
            </th>
            <th rowSpan={2} style={{ width: "350px" }}>
              CHECK POINTS
            </th>
            <th rowSpan={2} style={{ width: "200px" }}>
              ATTACH DOCUMENTS
            </th>
            <th rowSpan={2} style={{ width: "200px" }}>
              CHECKED BY-
            </th>
            <th colSpan={2}>FREQUENCY OF MAINT.</th>
            <th> OBSERVATIONS</th>
            <th rowSpan={2} style={{ width: "100px" }}>
              REMARKS
            </th>
          </tr>
          <tr>
            <th style={{ width: "60px" }}>Q</th>
            <th style={{ width: "250px" }}>WHERE</th>
            <th></th>
          </tr>
          {checkListMachineData.checkListMachineData[checklistType].map(
            (item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td align="center">{index + 1}</td>
                  <td align="center">
                    {isView ? (
                      item.check_point
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="check_point"
                        defaultValue={item.check_point}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>

                  <td>
                    <ActionCell>
                      <ActionContainer>
                        {item.imageUrl && (
                          <PreviewImage
                            src={item.imageUrl}
                            alt="image"
                            onClick={() => window.open(item.imageUrl, "_blank")}
                          />
                        )}
                        <UploadImageIconButton
                          id={`${index}-process-upload`}
                          tooltipTitle="upload"
                          sx={{}}
                          onChange={(e) => {
                            uploadFile(e, index);
                          }}
                        />

                        {item.imageUrl && (
                          <Tooltip title="Delete">
                            <StyledIconButton
                              size="small"
                              onClick={() =>
                                handleChecklist(
                                  { target: { name: "imageUrl", value: null } },
                                  index,
                                )
                              }
                            >
                              <DeleteOutline fontSize="small" />
                            </StyledIconButton>
                          </Tooltip>
                        )}
                      </ActionContainer>
                    </ActionCell>
                  </td>

                  <td align="center">
                    {isView ? (
                      item.check_point
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="check_by"
                        defaultValue={item.check_by}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView
                      ? item.freq_q
                      : CustomSelect(
                          "freq_q",
                          item.freq_q,
                          index,
                          "✓",
                          "✖",
                          "80px",
                        )}
                    {/* {isView ? (
                      item.freq_q
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="freq_q"
                        defaultValue={item.freq_q}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )} */}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    {item.freq_of_main}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.observation
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="observation"
                        defaultValue={item.observation}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                    {/* {isView ? (
                      item.observation
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="observation"
                        defaultValue={item.observation}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )} */}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      {isView
                        ? item.remarks
                        : CustomSelect(
                            "remarks",
                            item.remarks,
                            index,
                            "OK",
                            "NOT OK",
                            "100px",
                          )}
                      {!isView && (
                        <Tooltip title="delete row">
                          <IconButton
                            onClick={() => {
                              deleteRow(index);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </td>
                </tr>
              </React.Fragment>
            ),
          )}
        </tbody>
        <tr>
          <td align="left" colSpan={3}>
            Electrician / Fitter Signature:
            {isView ? (
              checkListMachineData.electrician_sign
            ) : (
              <input
                autoComplete="off"
                className="input"
                style={{ border: "2px solid #f9e0e0", width: "200px" }}
                name="maintenance_engg"
                defaultValue={checkListMachineData.electrician_sign}
                onChange={(e) => {
                  checkListMachineData.electrician_sign = e.target.value;
                }}
              />
            )}
          </td>
          <td align="left" colSpan={3}>
            Maintenance Engg.:
            {isView ? (
              checkListMachineData.maintenance_engg
            ) : (
              <input
                autoComplete="off"
                className="input"
                name="maintenance_engg"
                style={{ border: "2px solid #f9e0e0", width: "200px" }}
                defaultValue={checkListMachineData.maintenance_engg}
                onChange={(e) => {
                  checkListMachineData.maintenance_engg = e.target.value;
                }}
              />
            )}
          </td>

          <td colSpan={2}>
            Checked date:
            {checkListMachineData.preventive_time}
            {/* {isView ? ( */}
            {/*   formatDate(checkListMachineData.preventive_date) */}
            {/* ) : ( */}
            {/*   <Wrapper style={{ gap: "5px" }}> */}
            {/*     <DatePicker */}
            {/*       name="preventive_date" */}
            {/*       format="dd/MM/yyyy" */}
            {/*       InputProps={{ */}
            {/*         disableUnderline: true, */}
            {/*       }} */}
            {/*       style={{ */}
            {/*         backgroundColor: "#f8f9fa", */}
            {/*       }} */}
            {/*       value={checkListMachineData.preventive_date || null} */}
            {/*       onChange={(date) => { */}
            {/*         checkListMachineData.preventive_date = date; */}
            {/*         setRender((prev) => !prev); */}
            {/*       }} */}
            {/*     /> */}
            {/*   </Wrapper> */}
            {/* )} */}
          </td>
        </tr>
      </Table>
    </div>
  );
};

export default PredictiveTableForm;
