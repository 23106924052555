import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import ChecklistEditTable from "./ChecklistEditTable/ChecklistEditTable";
import { Wrapper } from "../../../../components/Control/Flexbox";
import { Button } from "@material-ui/core";
import { Add, AddIcCall, CloudUpload } from "@material-ui/icons";
import TableToExcel from "@dayalk/table-to-excel";
// import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import useConfirm from "../../../../components/Control/useConfirm";
import {
  Air_Decay,
  Air_Leaking_Testing,
  Bending_Machine,
  Bending_Machine_01,
  Bending_Machine_02,
  Benjo_Press_Machine,
  Blockage_Testing_Machine,
  Bust_Testing_Machine,
  Centre_Lathe_Machine,
  Clamping,
  Crimping_Machine,
  Cutting_Machine,
  Deburring_Machine,
  Drill_Machine,
  End_Forming_Machine,
  Farule_Fitting_Machine,
  Flushing_Machine,
  Grinding,
  High_Pressure_Leakage,
  Hydraullic_Press_1,
  Hydraullic_Press_2,
  Induction_Brazing_Machine,
  Lazer_Marking,
  Magna_Flux,
  NRV_Testing_Machine,
  Oil_Flushing_Machine,
  ORing_Testing_Machine,
  Oven,
  Pad_Printing,
  Pera_Flange_Machine,
  Pipe_Flairing_Machine,
  Polishing_Machine,
  Radial_Drill_Machine,
  Soco_Cutter_Machine,
  Spot_Welding,
  Ss_Furnace,
  Surface_Grinder,
  Tig_Welding,
  T_Drill_Machine,
  Ultrasonic_1,
  Ultrasonic_2,
} from "./allmachineDATA";
import SaveIcon from "@material-ui/icons/Save";
import lodash from "lodash";
import { useQuery } from "../../../../components/Control/useQuery";
import {
  Flushing,
  Leakage_Testing,
  Pipe_Bending,
  Ultrasonic,
} from "./predictiveData";
import Modal from "../../../../components/Control/Modal";
import FileUploadList from "./htmlTableFormForMaintenance/FileUpload";
import Loader from "../../../../components/Control/Loader";
const tableType = lodash.cloneDeep({
  Spot_Welding: Spot_Welding,
  Grinding: Grinding,
  Bending_Machine_01: Bending_Machine_01,
  Induction_Brazing_Machine: Induction_Brazing_Machine,
  NRV_Testing_Machine: NRV_Testing_Machine,
  Pera_Flange_Machine: Pera_Flange_Machine,
  Bending_Machine_02: Bending_Machine_02,
  Flushing_Machine: Flushing_Machine,
  Pipe_Flairing_Machine: Pipe_Flairing_Machine,
  Flushing: Flushing,
  Leakage_Testing: Leakage_Testing,
  Pipe_Bending: Pipe_Bending,
  Ultrasonic: Ultrasonic,
  Tig_Welding: Tig_Welding,
  Clamping: Clamping,
  Ultrasonic_1: Ultrasonic_1,
  Oven: Oven,
  Farule_Fitting_Machine: Farule_Fitting_Machine,
  Magna_Flux: Magna_Flux,
  Lazer_Marking: Lazer_Marking,
  Air_Decay: Air_Decay,
  Soco_Cutter_Machine: Soco_Cutter_Machine,
  Crimping_Machine: Crimping_Machine,
  Ss_Furnace: Ss_Furnace,
  Cutting_Machine: Cutting_Machine,
  Benjo_Press_Machine: Benjo_Press_Machine,
  Deburring_Machine: Deburring_Machine,
  Oil_Flushing_Machine: Oil_Flushing_Machine,
  Polishing_Machine: Polishing_Machine,
  End_Forming_Machine: End_Forming_Machine,
  Pad_Printing: Pad_Printing,
  Centre_Lathe_Machine: Centre_Lathe_Machine,
  Hydraullic_Press_1: Hydraullic_Press_1,
  Radial_Drill_Machine: Radial_Drill_Machine,
  Ultrasonic_2: Ultrasonic_2,
  Hydraullic_Press_2: Hydraullic_Press_2,
  Surface_Grinder: Surface_Grinder,
  Blockage_Testing_Machine: Blockage_Testing_Machine,
  Drill_Machine: Drill_Machine,
  Air_Leaking_Testing: Air_Leaking_Testing,
  T_Drill_Machine: T_Drill_Machine,
  High_Pressure_Leakage: High_Pressure_Leakage,
  Bust_Testing_Machine: Bust_Testing_Machine,
  ORing_Testing_Machine: ORing_Testing_Machine,
  Bending_Machine: Bending_Machine,
});

const LinkingToChecklistComponent = () => {
  const { id, checklistType } = useParams();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const [changeLanguage, setChangeLanguage] = useState("english");
  const [render, setRender] = useState(false);

  const [checkListMachineData, setCheckListMachineData] = useState({
    checkListMachineData: {
      Spot_Welding: [],
      Grinding: [],
      Bending_Machine_01: [],
      Induction_Brazing_Machine: [],
      NRV_Testing_Machine: [],
      Pera_Flange_Machine: [],
      Bending_Machine_02: [],
      Flushing_Machine: [],
      Pipe_Flairing_Machine: [],
      Flushing: [],
      Leakage_Testing: [],
      Pipe_Bending: [],
      Ultrasonic: [],
      Tig_Welding: [],
      Clamping: [],
      Ultrasonic_1: [],
      Oven: [],
      Farule_Fitting_Machine: [],
      Magna_Flux: [],
      Lazer_Marking: [],
      Air_Decay: [],
      Soco_Cutter_Machine: [],
      Crimping_Machine: [],
      Ss_Furnace: [],
      Cutting_Machine: [],
      Benjo_Press_Machine: [],
      Deburring_Machine: [],
      Oil_Flushing_Machine: [],
      Polishing_Machine: [],
      End_Forming_Machine: [],
      Pad_Printing: [],
      Centre_Lathe_Machine: [],
      Hydraullic_Press_1: [],
      Radial_Drill_Machine: [],
      Ultrasonic_2: [],
      Hydraullic_Press_2: [],
      Surface_Grinder: [],
      Blockage_Testing_Machine: [],
      Drill_Machine: [],
      Air_Leaking_Testing: [],
      T_Drill_Machine: [],
      High_Pressure_Leakage: [],
      Bust_Testing_Machine: [],
      ORing_Testing_Machine: [],
      Bending_Machine: [],
    },
  });

  useEffect(() => {
    axios
      .get(`/api/getChecklistById/${id}`)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          if (!data.document_name) {
            const values = { ...data };
            values.checkListMachineData[checklistType] =
              tableType[checklistType];
            setCheckListMachineData(values);
          }
          setCheckListMachineData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [render]);

  const handleSubmit = async (status) => {
    return new Promise(async (resolve, reject) => {
      if (!checkListMachineData.last_maintenance_date) {
        alert("Please choose a date field?");
        return resolve(false);
      }

      if (status === "completed") {
        checkListMachineData.document_name = checklistType;
        checkListMachineData.status = status;

        axios
          .put(`/api/updateChecklist/${id}`, checkListMachineData)
          .then((res) => {
            window.alert("Successfully done");
            resolve(true);
          })
          .then(() => {
            history.goBack();
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      }

      if (status === "started") {
        checkListMachineData.document_name = checklistType;
        checkListMachineData.status = status;

        axios
          .put(`/api/updateChecklist/${id}`, checkListMachineData)
          .then((res) => {
            window.alert("Successfully done");
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      }
    });
  };

  const handleChecklist = (e, index) => {
    const values = { ...checkListMachineData };
    values.checkListMachineData[checklistType][index][e.target.name] =
      e.target.value;
    setCheckListMachineData(values);
  };

  const excelDownload = () => {
    const tableId = `${checkListMachineData.document_type}`;
    const originalTable = document.getElementById(tableId);
    const clonedTable = originalTable.cloneNode(true);

    const inputs = clonedTable.querySelectorAll("input");
    inputs.forEach((input) => {
      const value = input.value;
      const parentTd = input.parentElement;
      parentTd.textContent = value;
    });

    document.body.appendChild(clonedTable);

    TableToExcel.convert(clonedTable, {
      name: `Check List Report - ${checkListMachineData.document_type}.xlsx`,
      sheet: {
        name: "Check List Report",
      },
    });

    document.body.removeChild(clonedTable);
  };

  const deleteRow = (index) => {
    const values = { ...checkListMachineData };
    values.checkListMachineData[checklistType]?.splice(index, 1);
    setCheckListMachineData(values);
  };

  const uploadFile = async (e, index) => {
    if (!handleSubmit("started")) return;
    const file = e.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      alert("Please upload only image files");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    setIsLoading(true);
    try {
      const res = await axios.patch(
        `/api/preventiveChecklist/uploadFile/${id}/${checklistType}/${index}`,
        formData,
      );
      if (res.data) {
        alert("Image uploaded successfully");
        setRender((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
      alert("Failed to upload image");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        CHECKLIST OF MACHINE MAINTENANCE
        <Wrapper>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#003566",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              startIcon={<Add />}
              onClick={(e) => {
                const values = { ...checkListMachineData };
                values.checkListMachineData[checklistType].push({});
                setCheckListMachineData(values);
              }}
            >
              ADD ROW
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#003566",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              // startIcon={<DescriptionSharpIcon />}
              onClick={(e) => excelDownload()}
            >
              Excel Download
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#003566",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
              onClick={(e) => handleSubmit("started")}
            >
              Save For Later
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUpload />}
              onClick={(e) => handleSubmit("completed")}
            >
              Submit
            </Button>
          </div>
        </Wrapper>
      </div>
      <ChecklistEditTable
        {...{
          checkListMachineData,
          handleChecklist,
          changeLanguage,
          checklistType,
          isView,
          uploadFile,

          uploadFile,
          deleteRow,
        }}
      />
      {checklistType === "None" && "NOT AVAILABLE "}
    </div>
  );
};

export default LinkingToChecklistComponent;
