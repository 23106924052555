import { IconButton, Tooltip } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";

export const UploadImageIconButton = (props) => {
  const { onChange, accept, multiple, id, tooltipTitle, sx } = props;
  return (
    <>
      <div>
        <input
          id={id ? id : "contained-button-file"}
          type="file"
          name={"excelFile"}
          multiple={multiple}
          accept={accept || "image/png,image/jpeg,image/jpg,application/pdf"}
          onChange={onChange}
          style={{ display: "none" }}
        />
        <label htmlFor={id ? id : "contained-button-file"}>
          <Tooltip
            title={tooltipTitle?.toUpperCase()}
            enterDelay={300}
            leaveDelay={200}
          >
            <IconButton
              component="span"
              color="primary"
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(25, 118, 210, 0.04)",
                },
                ...sx,
              }}
            >
              <CloudUpload style={{ ...sx }} />
            </IconButton>
          </Tooltip>
        </label>
      </div>
    </>
  );
};
