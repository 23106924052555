import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2), // Reduced padding
    maxWidth: 800,
    margin: "0 auto",
    minWidth: 500,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2), // Reduced gap
  },
  uploadButton: {
    padding: theme.spacing(1, 2.5), // Reduced padding
    borderRadius: theme.spacing(0.5), // Reduced border radius
    textTransform: "none",
    fontSize: 14, // Reduced font size
    fontWeight: 500,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableContainer: {
    width: "100%",
    marginTop: theme.spacing(1.5), // Reduced margin
    "& .MuiTableCell-root": {
      padding: theme.spacing(1), // Compact cell padding
      height: 40, // Reduced cell height
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(0.5), // Reduced icon button padding
    },
  },
  headerCell: {
    fontWeight: 600,
    backgroundColor: theme.palette.grey[50],
  },
  iconButton: {
    padding: 6,
  },
}));

const FileUploadList = ({ checklistType, id, listId }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/preventiveChecklist/${id}/${checklistType}/list/${listId}`)
      .then((res) => {
        if (res?.data) {
          setFiles(res?.data?.files || []);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [render]);

  const handleFileUpload = (e) => {
    if (!window.confirm("Are you sure you want to upload these files?")) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append("files", files[key]);
    }
    setIsLoading(true);
    e.target.value = "";
    axios
      .patch(
        `/api/preventiveChecklist/uploadFile/${id}/${checklistType}/${listId}`,
        formData,
      )
      .then((res) => {
        if (res.data) {
          alert("File(s) uploaded successfully");
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleRemoveFile = async (index) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }
    axios
      .delete(
        `/api/preventiveChecklist/deleteFile/${id}/${checklistType}/${listId}/${index}`,
      )
      .then((res) => {
        if (res.data) {
          alert("File deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getViewUrl = (fileName, fileUrl) => {
    if (fileName.includes(".xlsx") || fileName.includes(".xls"))
      return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        fileUrl,
      )}`;
    return fileUrl;
  };

  return (
    <Container className={classes.container}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" size={24} /> {/* Reduced size */}
      </Backdrop>

      <Box className={classes.contentWrapper}>
        <input
          accept="*/*"
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.uploadButton}
            startIcon={<CloudUploadIcon />}
          >
            Upload Files
          </Button>
        </label>

        {files.length > 0 && (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.headerCell}>
                    #
                  </TableCell>
                  <TableCell align="center" className={classes.headerCell}>
                    FILE NAME
                  </TableCell>
                  <TableCell align="center" className={classes.headerCell}>
                    ACTIONS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{file.name}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="View">
                        <IconButton
                          className={classes.iconButton}
                          component="a"
                          href={getViewUrl(file.name, file.url)}
                          target="_blank"
                          size="small"
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleRemoveFile(index)}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
};

export default FileUploadList;
