import React from "react";
import MaintenanceTableForm from "../htmlTableFormForMaintenance/MaintenanceTableForm";
import PredictiveTableForm from "../htmlTableFormForMaintenance/PredictiveTableForm";

const ChecklistEditTable = ({
  checkListMachineData,
  changeLanguage,
  handleChecklist,
  checklistType,
  isView,
  uploadFile,
  deleteRow,
}) => {
  return (
    <div style={{ paddingBottom: "4rem" }}>
      {checkListMachineData.document_type === "preventive" ? (
        <MaintenanceTableForm
          {...{
            checkListMachineData,
            changeLanguage,
            handleChecklist,
            checklistType,
            isView,
            uploadFile,
            deleteRow,
          }}
        />
      ) : (
        <PredictiveTableForm
          {...{
            checkListMachineData,
            changeLanguage,
            handleChecklist,
            checklistType,
            isView,
            uploadFile,
            deleteRow,
          }}
        />
      )}
    </div>
  );
};

export default ChecklistEditTable;
